var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Receivingprintdetails" }, [
    _c("div", { staticClass: "nav-title" }, [
      _vm._v(" 订单的结算金额未减平台服务费，平台服务费在结算单统一扣减 "),
    ]),
    _c("div", { staticClass: "auto-table-flex" }, [
      _c(
        "div",
        { staticClass: "ctns" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-style": { color: "#333333", fontSize: "16px" },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "100",
                  align: "center",
                  label: "序号",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "address", align: "center", label: "订单编号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.order_no)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "address", align: "center", label: "支付时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.time(scope.row.order_pay_time)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  align: "center",
                  label: "收费支付金额(元)",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tool.toDecimal2(
                                (scope.row.freight_amount * 10000) / 100 / 10000
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  align: "center",
                  label: "售后退费金额(元)",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tool.toDecimal2(
                                (scope.row.aftersale_amount * 10000) /
                                  100 /
                                  10000
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  align: "center",
                  label: "结算比例(%)",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$empty.empty(scope.row.liquidate_rate)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  align: "center",
                  label: "结算金额(元)",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tool.toDecimal2(
                                (scope.row.liquidate_amount * 10000) /
                                  10000 /
                                  10000
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "btn" },
      [_c("el-button", { on: { click: _vm.fanhui } }, [_vm._v("返回")])],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }